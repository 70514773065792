<template>
  <div v-if="this.$store.state.page.isMobile">
    <div class="quick-cart" @click="toggleCart" v-if="cartItem.length > 0">
      <button class="btn-cart"><span>{{ cartItem.length }}</span></button>
    </div>
    <transition name="fade">
      <div class="modal fade" :class="{'in': isView }" id="modalCart" v-if="isView" style="display: block;">
        <div class="modal-dialog" >
          <div class="">
            <div class="modal-body">
              <div class="modal-cart">
                <div class="close">
                  <button @click="closeCart" class="btn-close" data-dismiss="modal"></button></div>
                <div class="box1">
                  <p>배팅 슬립</p>
<!--                  <p>배당변경 자동적용</p>-->
<!--                  <div>-->
<!--                    <label><input type="checkbox" class="switch" :checked="isUpdate" @click="isUpdate = !isUpdate"><span><em></em></span></label>-->
<!--                  </div>-->
                </div>
                <div class="tab-box">
                  <!-- <div class="btns">
                    <button :class="{'active': isSingle}" @click="changeSingle(true)">싱글</button>
                    <button :class="{'active': !isSingle}" @click="changeSingle(false)">다폴더</button>
                  </div> -->
                  <button @click="clearCart" class="btn-trash"></button>
                </div>
                <div class="body">
                  <div class="tab-cont" v-if="!process && !complete && cartItem.length === 0">
                    베팅을 원하시면 <br>
                    원하시는 경기의 배당을 선택해 주세요.
                  </div>
                  <div class="tab-cont" v-else-if="!process && !complete && cartItem.length > 0">
                    <!-- <MSingle v-if="isSingle" :clear-cart="clearCart" :single-bet-cart-set-amount="singleBetCartSetAmount" :is-update="isUpdate"></MSingle>
                    <MMulti v-else :clear-cart="clearCart" :is-update="isUpdate"></MMulti> -->
                    <MMulti :clear-cart="clearCart" :is-update="isUpdate"></MMulti>
                  </div>
                  <div class="tab-cont" style="text-align: center;" v-else-if="process && !complete">
                    <div class="cont" style="">
                      <Loading></Loading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import MSingle from './MEuroepanSingle'
import MMulti from './MEuropeanMulti'
import Loading from '@/components/Loading'
import {mapState} from "vuex";

export default {
  name: "MEuropeanCart",
  components: {
    MMulti,
    // MSingle,
    Loading
  },
  data: function(){
    return {
      // 베팅금액
      isSingle: false,
      process: false,
      complete: false,
      upHere: false,
      isUpdate: true,
    }
  },
  computed: {
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    ...mapState({
      isView: state => state.modals.cart,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  watch:{
    isSingle: function(val){
      if (val){
        this.singleBetCartSetAmount()
      }
    },
    cartItem: {
      handler(val){
        if (val.length === 0) this.$store.dispatch('INVISIBLE_MODAL', 'cart')
        if (val.length === 1) this.isSingle = true;
        if (val.length > 1) this.isSingle = false;
        // 카트에 보너스 타입이있을시 해당조건 아래일시 보너스 삭제 삭제되도록
        const bonus = val.find(e => e.betType === '보너스');
        if (bonus) {
          const folderCnt = bonus.homeNameKr.split('폴더')[0];
          if (val.length <= Number(folderCnt) ) {
            this.$store.dispatch('DELETE_CART_ITEM', bonus);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    clearCart: function (){
      return this.$store.dispatch('CLEAR_SPORT_CART');
    },
    closeCart: function (){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    changeSingle: function(is){
      this.isSingle = is;
    },
    singleBetCartSetAmount(){
      for (const c in this.cartItem){
        this.cartItem[c].betAmount = 0;
        this.cartItem[c].totalResult = this.cartItem[c].betAmount * this.cartItem[c].odds;
      }
    },
    toggleCart: function(){
      return this.$store.dispatch('SHOW_MODAL', 'cart')
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
