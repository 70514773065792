<template>
  <section class="pc" v-if="!isMobile" style="min-height: 800px;">
<!--    <h2 class="subTitle">실시간</h2>-->
    <div class="game_contents" style="min-height: 500px;">
      <table style="width: 86%;" >
        <tbody>
        <tr>
          <!--    왼쪽사이드바      -->
          <SideBar v-if="isLoading" :origin-list="originList" :filter-by-games="filterByGames" :select-game="moveDetail" :check-block-game="checkBlockGame"></SideBar>
          <td style="text-align: center; width:15%;" v-else>
            <Loading></Loading>
          </td>
          <!-- left -->
          <td style="padding:0 10px 0 10px; width: 90%" v-if="isLoading">
            <List :origin-list="originList" :wait-list="waitList"  :add-cart="addCart" :check-cart="checkCart" :check-block-game="checkBlockGame" :block-type-setting="blockTypeSetting"></List>
          </td>
          <td style="padding:0 10px 0 10px; text-align: center; width: 90%;" v-else>
            <Loading></Loading>
          </td>
          <td class="game_right_wrap" :class="{'fixedCart': isScrollDown }" v-if="!sticky" valign="top" style="position: fixed; width: 10%;" >
            <NewCart :toggle-stick="toggleStick" :fixed="false" :socket="lsport_socket"></NewCart>
          </td>
          <td class="game_right_wrap" v-if="sticky" valign="top" style="position: absolute; width: 10%;">
            <NewCart :toggle-stick="toggleStick" :fixed="false" :socket="lsport_socket" ></NewCart>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <router-link to="/main" class="btn-prev"></router-link>
      </div>
      <h2>인플레이</h2>
    </div>
    <div class="categorys">
      <button @click="changeCategory('')" :class="{'active': category === ''}">전체</button>
      <button @click="changeCategory(6046)" :class="{'active': category === 6046}"><img src="@/assets/img/ico/1.png"></button>
      <button @click="changeCategory(154914)" :class="{'active': category === 154914}"><img src="@/assets/img/ico/11.png"></button>
      <button @click="changeCategory(48242)" :class="{'active': category === 48242}"><img src="@/assets/img/ico/3.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/4.png"></button>-->
      <button @click="changeCategory(154830)" :class="{'active': category === 154830}"><img src="@/assets/img/ico/5.png"></button>
      <button @click="changeCategory(131506)" :class="{'active': category === 131506}"><img src="@/assets/img/ico/6.png"></button>
      <button @click="changeCategory(154830)" :class="{'active': category === 154830}"><img src="@/assets/img/ico/2.png"></button>
      <button @click="changeCategory(687890)" :class="{'active': category === 687890}"><img src="@/assets/img/ico/77.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/44.png"></button>-->
    </div>

    <!-- 인디케이터 -->
    <div class="m-game-wrap" v-if="!isLoading" style="min-height: 300px; text-align: center; padding-top: 50px;">
      <div class="m-game-body">
        <div class="m-game">
          <Loading></Loading>
        </div>
      </div>
    </div>

    <!-- 인디케이터 -->
    <div class="m-game-wrap" v-if="isLoading && lists.length === 0" style="min-height: 100px; text-align: center;">
      <div class="m-game-body">
        <div class="m-game">
          <div class="team">
            <div class="name">
              <span>진행중인 경기가</span>
              <span></span>
              <span>없습니다.</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MList v-if="isLoading && lists.length > 0" :origin-list="lists" :add-cart="addCart" :check-cart="checkCart" :check-block-game="checkBlockGame"></MList>

    <!-- 경기일정   -->
    <div class="m-game-wrap">
      <div class="m-game-head">
        <div class="tit">
          <strong>경기일정</strong>
        </div>
      </div>
      <div class="m-game-body bg-white" v-for="(row, index) in waitList" :key="'inplayWaitList' + index ">
        <div class="m-game">
          <div class="day">
            <p class="t3 mb10" style="font-weight: bold;">{{ row.startTime | formatDate('YYYY-MM-DD HH:mm') }}</p>
            <img v-if="row.sport.icon" :src="row.sport.icon" class="m-r-5 mb10">
            <span class="t2" style="font-weight: bold;">{{ row.league | leagueName }}</span>
            <img style=" margin-right: 10px; margin-left: 10px;" v-if="row.league.icon" :src="row.league.icon"
                 alt="">
            <img style=" margin-right: 10px; margin-left: 10px;" v-else-if="row.location.icon" :src="row.location.icon"
                 alt="">
          </div>
          <div class="team">
            <div class="name">
              <span style="font-weight: bold;">{{ row.homeTeam  | teamName }}</span>
              <span> vs </span>
              <span style="font-weight: bold;">{{ row.awayTeam  | teamName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-aside-live"></div>
    <MCart :fixed="true"></MCart>
  </div>
</template>

<script>
// 데스크탑
import SideBar from '@/components/Game/Sports/LsportInplay/SideBar'
import List from '@/components/Game/Sports/LsportInplay/List'
import NewCart from '@/components/Betting/LsportInplay/InplayCart'

// 모바일
import MCart from '@/components/Betting/LsportInplay/MInplayCart'
import MList from '@/components/Game/Sports/LsportInplay/MInplayList'

//공통
import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "Inplay",
  components: {
    SideBar,
    List,
    NewCart,
    MCart,
    MList,
    Loading
    // RingLoader,
  },
  data: function(){
    return {
      type: 'korean',
      category: null,
      isLoading: false,
      selectedGame: null,
      search: false,
      keyword: null,
      sticky: false,
      lists : null,
      isScrollDown: false,
      payload: {},
      initTime: null,
      lsport_socket: null,
    }
  },
  computed: {
    ...mapState({
      originList: state => state.lsport_inplay.originList,
      waitList: state => state.lsport_inplay.waitList,
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
      cartItem: state => state.lsport_inplay.cart,
    }),
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    },
    /* 인플레이 */
    blockTypeSetting: function(){
      return this.$store.getters['INPLAY_BLOCK_TYPE_SETTING'];
    },
  },
  watch:{
    selectedGame(val){
      if (!val){
        this.lists = null;
        this.initFolder();
      }
    }
  },
  mounted: function() {
    // this.$store.dispatch('SHOW_ERR_MSG3','조합인플레이는 점검중입니다.');
    //this.$store.dispatch('SHOW_ERR_MSG', MSG.error.socketNotConnected);
    //this.$router.push({ path: '/main'})
    setTimeout(() => {
      if (!this.lsport_socket.connected) {
        this.$store.dispatch('SHOW_ERR_MSG', MSG.error.socketNotConnected);
        this.lsport_socket.client.connect();
        this.initFolder()
      }
    }, 2000)
    window.addEventListener('scroll', this.handleScroll)

    if (this.user){
      const config = this.user.members_detail_setting;
      if (!config.베팅권한.인플레이.active) {
        this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
        this.$router.push({ name: '메인' });
      }
    }
    this.initFolder()
    // this.reqWait();
    this.interval = setInterval(this.initFolder2, 20000);
    this.interval2 = setInterval(this.checkInitTime, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    checkInitTime(){
      const now = moment().unix();
      if (this.initTime && now - this.initTime  > 180){
        this.$store.dispatch('SHOW_ERR_MSG', '화면 로딩후 일정시간이 지나 인플레이 데이터의 갱신이 필요합니다.');
        this.initFolder();
        this.$store.dispatch('ALL_LSPORT_INPLAY_CLEAR_ITEM');
      }
    },
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > 200) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    // 경기요청
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_LSPORT_INPLAY_LIST', { payload }).then((result) => {
        if (result.data.success) {
          this.isLoading = true;
          this.lists = this.originList;
          this.initTime = moment().unix();
        } else {
          // console.log('인플레이 경기요청 에러', result)
          this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
        }
      })
    },
    // 경기요청
    initFolder2(payload){
      this.$store.dispatch('GET_LSPORT_INPLAY_LIST', { payload }).then((result) => {
        if (result.data.success) {
          this.lists = this.originList;
        } else {
          // console.log('인플레이 경기요청2 에러')
          this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
        }
      })
    },
    // reqWait(){
    //   this.$store.dispatch('GET_INPLAY_WAIT_LIST', { payload: {} })
    // },
    // 게임종목
    changeCategory: function(category){
      this.category = category;
      if (category === '') {
        return this.lists = this.originList;
      }
      this.lists = this.originList.filter(e=> e.sportId === category);
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.인플레이.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);

        // 같은 id의 게임이 이미 카트에 있을 때
        if (this.cartItem.find(item => item.gameId === game.id) && config.베팅권한.인플레이.combination) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.blockedCombination)
      } else return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);


      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        foldersStatus: folders.status,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueId: game.leagueId,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        marketsId: folders.marketId,
        startTime: game.startTime,
        odds1: folders.line,
        odds2: null,
        odds3: null,
        betType: '인플레이(엘스포츠)',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league?.maxAmount
      };
      cartItem.marketNameKr = folders.market.nameKr?.replace('{sw}', game.sport.periodName);
      cartItem.marketNameEn = folders.market.nameEn?.replace('{sw}', game.sport.periodName);

      if (game.sport.periodName === '반전') {
        cartItem.marketNameKr = folders.market.nameKr?.replace('1{sw}', '전반전')
        cartItem.marketNameEn = folders.market.nameEn?.replace('2{sw}', '후반전')
      }
      this.$store.dispatch('ADD_CART_LSPORT_INPLAY_ITEM', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets) {
      if(bets) {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      } else {
        return false
      }
      
    },

    moveDetail: function(game){
      this.$router.push({ name: '스포츠-엘스포츠(인플이)-자세히', query: { gid: game.id }})
    },
    uniq: function(array){
      return Array.from(new Set(array));
    },
    moveBack: function(){
      this.selectedGame = null;
    },
    filterByGames: function (sortType, target) {
      this.selectedGame = null;
      if (sortType === 'all') {
        return this.lists = this.originList;
      }
      if (sortType === 'league') {
        this.lists = this.originList.filter(e => e.leagueSeq === target);
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => e.sportSeq === target);
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.nameKr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.nameKr.indexOf(target) >= 0
        );
      }
    },
    toggleStick: function(){
      this.sticky = !this.sticky;
    },
    /* 서버 설정에 따라서 경기 베팅을 막는다.
    *  True
    * */
    checkBlockGame: function(){
    // checkBlockGame: function(game){
      //let blockConfig = {};

      /*
      if (game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_LSRPOT_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        let currentPeriod = 1;
        const time = game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
          // console.log(currentPeriod);
        }
        // console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00'){
          if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod > 4) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }
      }
      if (game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

      }
      if (game.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          if (Number(this.blockTypeSetting) === 1) this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = game.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }
      */
      return false;
    },
  },
}
</script>

<style scoped>
.event-detail-list-move {
  transition: transform 0.8s;
}
.event-detail-list {
  transition: transform 0.8s;
}
.fixedCart {
  top: 70px;
}
</style>
